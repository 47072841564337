@import '../styles/variables.scss';

.c-rr-header-nav {
  &__list {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
  }

  &__list-item {
    margin-right: 1rem;
  }

  &__item-link {
    @media screen and (min-width: $condensed-header-min) and (max-width: $condensed-header-max) {
      font-size: 0.875rem;
    }
  }
}

.c-rr-header--mobile {
  .c-rr-header-nav {
    &__list {
      display: flex;
      justify-content: center;
    }

    &__item-link {
      font-size: 0.8em;
      padding: 0.5em;
    }
  }
}

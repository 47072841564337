.c-rr-accordion {
  &__button {
    width: 100%;
    background-color: white;
    border: 0;
    display: flex;
    align-items: center;
    font-size: 1.17em;
    font-weight: bold;
    margin-bottom: 1rem;
  }

  &__button-caret {
    margin-right: 0.5em;
    transition: transform 250ms ease;

    &--open {
      transform: rotate(90deg);
    }
  }

  &--open {
    //
  }

  &--closed {
    //
  }

  &__pane {
    overflow: hidden;
    transition: height 250ms ease;
    height: auto;

    &--open {
      // height: 300px;
    }

    &--closed {
      // height: 0;
      // overflow: hidden;
    }
  }
}

@import '../styles/variables.scss';

.c-rr-header {
  height: 60px;
  color: white;
  background-color: $brand-color;
  padding: 0 1rem;

  &__container {
    height: 100%;
    display: flex;
    align-items: center;
  }

  &__menu-button {
    margin-top: 4px;

    &--settings {
      margin-right: 1rem;
    }

    &--main {
      margin-left: 1rem;
    }

    .c-rr-icon-button__icon {
      width: 25px;
    }
  }

  &__title {
    margin: 0;
    padding-right: 2rem;

    @media screen and (min-width: $condensed-header-min) and (max-width: $condensed-header-max) {
      font-size: 1rem;
    }
  }

  &--right {
    flex-grow: 1;
    display: flex;
    justify-content: flex-end;
  }

  &--mobile {
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
    height: 90px;

    .c-rr-header--right {
      flex-grow: 0;
    }

    .c-rr-header__container {
      height: 45px;
      justify-content: space-between;
    }

    .c-rr-header__menu-button {
      margin-top: 0;
      padding: 0;

      &--settings {
        margin-right: 0.5rem;
      }

      &--main {
        margin-left: 0.5rem;
      }
    }

    .c-rr-header__title {
      text-align: center;
      padding-bottom: 0.4rem;
      padding-right: 0;
      font-size: 1rem;
      margin-top: 1px;
    }
  }
}

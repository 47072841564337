// Colors: http://paletton.com/#uid=33o0d0kMSw+7pPVqHInQPlFZ86I
$brand-color: #027fa2;
$pane-color: #acd3de;
$accent-color: #ff6200;
$link: #0000ee;
$link-visited: #551a8b;
$link-light: #ffcb7f;

$warning-dark: #ffaa2a;
$warning-light: #ffe7c4;

$gray-medium: #bcbcbc;

// Breakpoints
$mobile-breakpoint: 768px;
$condensed-header-min: 769px;
$condensed-header-max: 970px;
